<template>
  <div>
    <!--  -->
    <create-header-report
      :is-edit="isEdit"
      :title="title"
      @save="save()"
      @editTitleModel="openRenameModal"
      v-if="ui.loading === false"
    />

    <!--  Report Filtration  -->
    <div class="report-details" v-if="ui.loading === false">
      <!-- Select report type -->
      <div class="row data-category">
        <div class="col-md-2 mt-3 mt-md-0">
          <!-- Multiselect for select report type -->
          <multiselect
            v-model="typeValue"
            :option-height="104"
            :options="multiSelect.types"
            :searchable="false"
            :show-labels="false"
            :allow-empty="false"
            :disabled="!isAdminstrator"
            label="title"
            placeholder=""
            selectedLabel="Selected"
            track-by="title"
            @select="selectType"
          >
            <template slot="singleLabel" slot-scope="props">
              <div>
                <span class="report-type">
                  Type
                </span>
                <span class="option__title">{{ props.option.title }}</span>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="">{{ props.option.title }}</span>
              </div>
            </template>
          </multiselect>
        </div>
        <div class="col-md-2 mt-3 mt-md-0">
          <!-- Multiselect for select agent -->
          <multiselect
            v-if="typeValue.type === 'agent'"
            v-model="selectedAgent"
            :option-height="104"
            :options="multiSelect.agents"
            :searchable="true"
            :show-labels="false"
            :allow-empty="false"
            :disabled="!isAdminstrator"
            label="title"
            placeholder=""
            selectedLabel="Selected"
            track-by="title"
            @select="selectAgent"
          >
            <template slot="singleLabel" slot-scope="props">
              <div>
                <img alt="Agent" src="@/assets/icons/icon-profile.svg" />
                <span class="option__title">{{ props.option.title }}</span>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="">{{ props.option.title }}</span>
              </div>
            </template>
          </multiselect>

          <!-- Multiselect for select agent -->
          <multiselect
            v-if="typeValue.type === 'processor'"
            v-model="selectedProcessor"
            :option-height="104"
            :options="multiSelect.processors"
            :searchable="true"
            :show-labels="false"
            :allow-empty="false"
            :disabled="!isAdminstrator"
            label="title"
            placeholder=""
            selectedLabel="Selected"
            track-by="title"
            @select="selectProcessor"
          >
            <template slot="singleLabel" slot-scope="props">
              <div>
                <img alt="Agent" src="@/assets/icons/icon-profile.svg" />
                <span class="option__title">{{ props.option.title }}</span>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="">{{ props.option.title }}</span>
              </div>
            </template>
          </multiselect>
        </div>
        <div class="col-md-2 mt-3 mt-md-0 ms-auto">
          <button
            class="btn btn-outline-dark w-100"
            @click="exportReport"
            :disabled="ui.loadingExport"
          >
            <span v-if="ui.loadingExport">loading...</span>
            <span v-else>Export Report</span>
          </button>
        </div>
      </div>

      <!-- Table filtration row -->
      <div class="row mt-3">
        <div class="col-md-6 col-lg-4 table-filter">
          <div id="table-filter" class="position-relative" parent>
            <div class="tippy-dropdown" name="tableFilter">
              <span class="report-type">
                Show
                <span
                  v-if="
                    (tableFilterPositions.revenue === true &&
                      typeValue.type !== 'processor') ||
                      (reportProcessor === true &&
                        tableFilterPositions.revenue === true)
                  "
                  >Revenue,</span
                >
                <span v-if="tableFilterPositions.units === true">Units,</span>
                <span
                  v-if="
                    (tableFilterPositions.expense === true &&
                      typeValue.type !== 'processor') ||
                      (reportProcessor === true &&
                        tableFilterPositions.expense === true)
                  "
                  >Expense,</span
                >
                <span
                  v-if="
                    tableFilterPositions.turnTime === true &&
                      typeValue.type === 'processor'
                  "
                  >Turn Time,</span
                >
                <span
                  v-if="typeValue.type !== 'company' && tableFilterPositions.withdrawal"
                  >Withdrawal</span
                >
                <span
                  v-if="tableFilterPositions.volumeFunded"
                  >Volume Funded</span
                >
                <span
                  class="normal"
                  v-if="
                    typeValue.type === 'company' &&
                      (tableFilterPositions.expense === true ||
                        tableFilterPositions.units === true ||
                        tableFilterPositions.revenue === true)
                  "
                  >Grouped by {{ this.groupedBy }}</span
                >
              </span>
              <img alt="Arrow" src="@/assets/icons/icon-drop-down-expand.svg" />
            </div>
            <tippy
              :appendTo="tableFilterAppend"
              animateFill="false"
              arrowType="round"
              boundary="window"
              class="setDate"
              distance="1"
              hideOnClick="true"
              interactive="true"
              placement="bottom-center"
              sticky="true"
              theme="dropdown"
              to="tableFilter"
              trigger="click"
              zIndex="99"
            >
              <div class="tippy-filter-table">
                <div class="row">
                  <div class="col-5 p-0">
                    <span>
                      Show
                    </span>
                    <div
                      v-if="
                        typeValue.type !== 'processor' ||
                          reportProcessor === true
                      "
                      class="form-check mt-2"
                    >
                      <input
                        id="fieldRevenue"
                        v-model="tableFilterPositions.revenue"
                        class="form-check-input"
                        type="checkbox"
                      />
                      <label class="form-check-label" for="fieldRevenue">
                        Revenue
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input
                        id="fieldUnits"
                        v-model="tableFilterPositions.units"
                        class="form-check-input"
                        type="checkbox"
                      />
                      <label class="form-check-label" for="fieldUnits">
                        Units
                      </label>
                    </div>
                    <div
                      v-if="
                        typeValue.type !== 'processor' ||
                          reportProcessor === true
                      "
                      class="form-check mt-2"
                    >
                      <input
                        id="fieldExpense"
                        v-model="tableFilterPositions.expense"
                        class="form-check-input"
                        type="checkbox"
                      />
                      <label class="form-check-label" for="fieldExpense">
                        Expense
                      </label>
                    </div>
                    <div
                      v-if="typeValue.type === 'processor'"
                      class="form-check mt-2"
                    >
                      <input
                        id="fieldFilterTurnTime"
                        v-model="tableFilterPositions.turnTime"
                        class="form-check-input"
                        type="checkbox"
                      />
                      <label class="form-check-label" for="fieldFilterTurnTime">
                        Turn Time
                      </label>
                    </div>
                    <div
                      v-if="typeValue.type !== 'company'"
                      class="form-check mt-2"
                    >
                      <input
                        id="fieldFilterWithdrawal"
                        v-model="tableFilterPositions.withdrawal"
                        class="form-check-input"
                        type="checkbox"
                      />
                      <label class="form-check-label" for="fieldFilterWithdrawal">
                        Withdrawal
                      </label>
                    </div>
                    <div
                      class="form-check my-2"
                    >
                      <input
                        id="fieldVolumeFunded"
                        v-model="tableFilterPositions.volumeFunded"
                        class="form-check-input"
                        type="checkbox"
                      />
                      <label class="form-check-label" for="fieldVolumeFunded">
                        Volume Funded
                      </label>
                    </div>
                    <span>
                      Loan Types
                    </span>
                    <div
                      class="form-check mt-2"
                      v-for="(date, index) in loanType"
                      :key="index"
                    >
                      <input
                        :id="`field${index}`"
                        v-model="date.checked"
                        class="form-check-input"
                        type="checkbox"
                      />
                      <label class="form-check-label" :for="`field${index}`">
                        {{date.name}}
                      </label>
                    </div>
                  </div>
                  <div
                    class="col-7 p-0"
                    v-if="
                      typeValue.type === 'company' &&
                        (tableFilterPositions.expense === true ||
                          tableFilterPositions.units === true ||
                          tableFilterPositions.revenue === true)
                    "
                  >
                    <span>
                      Grouped by
                    </span>
                    <div class="form-check mt-2">
                      <input
                        id="fieldMonth"
                        v-model="groupedBy"
                        class="form-check-input"
                        type="radio"
                        value="Month"
                      />
                      <label class="form-check-label" for="fieldMonth">
                        Month
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input
                        id="fieldYear"
                        v-model="groupedBy"
                        class="form-check-input"
                        type="radio"
                        value="Year"
                      />
                      <label class="form-check-label" for="fieldYear">
                        Year
                      </label>
                    </div>
                    <div
                      class="form-check mt-2"
                      v-if="tableFilterPositions.expense === false"
                    >
                      <input
                        id="fieldLoanType"
                        v-model="groupedBy"
                        class="form-check-input"
                        type="radio"
                        value="Loan Type"
                      />
                      <label class="form-check-label" for="fieldLoanType">
                        Loan Type
                      </label>
                    </div>
                    <div
                      class="form-check mt-2"
                      v-if="tableFilterPositions.expense === false"
                    >
                      <input
                        id="fieldState"
                        v-model="groupedBy"
                        class="form-check-input"
                        type="radio"
                        value="State"
                      />
                      <label class="form-check-label" for="fieldState">
                        State
                      </label>
                    </div>
                    <div
                      class="form-check mt-2"
                      v-if="
                        tableFilterPositions.expense === true &&
                          tableFilterPositions.units === false &&
                          tableFilterPositions.revenue === false
                      "
                    >
                      <input
                        id="fieldCategoryExpense"
                        v-model="groupedBy"
                        class="form-check-input"
                        type="radio"
                        value="Category"
                      />
                      <label
                        class="form-check-label"
                        for="fieldCategoryExpense"
                      >
                        Category (Expense)
                      </label>
                    </div>
                    <div
                      class="form-check mt-2"
                      v-if="
                        tableFilterPositions.expense === true &&
                          tableFilterPositions.units === false &&
                          tableFilterPositions.revenue === false
                      "
                    >
                      <input
                        id="fieldVendorExpense"
                        v-model="groupedBy"
                        class="form-check-input"
                        type="radio"
                        value="Vendor"
                      />
                      <label class="form-check-label" for="fieldVendorExpense">
                        Vendor (Expense)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </tippy>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 mt-3 mt-md-0">
          <div id="pickDate">
            <!-- Dropdown for date -->
            <div class="tippy-dropdown" name="chooseDate">
              {{ datePicked.title }} <span>{{ datePicked.range }}</span>
              <img alt="Arrow" src="@/assets/icons/icon-drop-down-expand.svg" />
            </div>
            <tippy
              :appendTo="append"
              animateFill="false"
              arrowType="round"
              boundary="window"
              class="setDate"
              distance="1"
              hideOnClick="true"
              interactive="true"
              placement="bottom-center"
              sticky="true"
              theme="dropdown"
              to="chooseDate"
              trigger="click"
              zIndex="99"
            >
              <div class="setDate-block">
                <ul>
                  <li
                    v-for="(date, index) in dateOptions"
                    :key="index"
                    :class="{ 'is-checked': date.isChecked === true }"
                    @click="setDate(date.id)"
                  >
                    {{ date.title }} <span>({{ date.date }})</span>
                  </li>
                </ul>
                <div class="custom-date border-top-light">
                  <div class="row">
                    <div class="col-6 ps-0">
                      <date-range-picker
                        ref="picker"
                        v-model="dateRange"
                        :auto-apply="true"
                        :locale-data="{
                          firstDay: 1,
                          format: 'DD-MM-YYYY HH:mm:ss'
                        }"
                        :ranges="false"
                        :singleDatePicker="true"
                        :time-picker24-hour="false"
                        :timePicker="false"
                        class="daterange-control"
                        opens="center"
                        @select="datepickerSelect"
                      >
                        <template v-slot:input="picker">
                          {{ picker.startDate | date }}
                          <img
                            alt=""
                            class="position-absolute"
                            src="@/assets/icons/icon-calender.svg"
                          />
                        </template>
                      </date-range-picker>
                    </div>
                    <div class="col-6 pe-0">
                      <date-range-picker
                        ref="picker"
                        v-model="dateRangeEnd"
                        :auto-apply="true"
                        :locale-data="{
                          firstDay: 1,
                          format: 'DD-MM-YYYY HH:mm:ss'
                        }"
                        :ranges="false"
                        :singleDatePicker="true"
                        :time-picker24-hour="false"
                        :timePicker="false"
                        class="daterange-control"
                        opens="center"
                        @select="datepickerSelectEnd"
                      >
                        <template v-slot:input="picker">
                          {{ picker.startDate | date }}
                          <img
                            alt=""
                            class="position-absolute"
                            src="@/assets/icons/icon-calender.svg"
                          />
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div>
            </tippy>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 mt-3 mt-lg-0">
          <div id="sortable" parent>
            <!-- Dropdown for table filter -->
            <div class="tippy-dropdown sortable" name="sortable">
              <span>Sort by</span> {{ sortBy }} ({{ order }})
              <img alt="Arrow" src="@/assets/icons/icon-drop-down-expand.svg" />
            </div>
            <tippy
              :appendTo="sortableAppend"
              animateFill="false"
              arrowType="round"
              boundary="window"
              class="setDate"
              distance="1"
              hideOnClick="true"
              interactive="true"
              placement="bottom-center"
              sticky="true"
              theme="dropdown"
              to="sortable"
              trigger="click"
              zIndex="99"
            >
              <div class="sortable-block">
                <span>Sort by</span>
                <div
                  v-if="
                    (typeValue.type !== 'processor' ||
                      reportProcessor === true) &&
                      tableFilterPositions.revenue === true
                  "
                  class="form-check mt-3 mb-3"
                >
                  <input
                    id="fieldSortRevenue"
                    v-model="sortBy"
                    class="form-check-input"
                    type="radio"
                    value="Revenue"
                  />
                  <label class="form-check-label" for="fieldSortRevenue">
                    Revenue
                  </label>
                </div>
                <div
                  v-if="tableFilterPositions.units === true"
                  class="form-check mt-3 mb-3"
                >
                  <input
                    id="fieldSortUnits"
                    v-model="sortBy"
                    class="form-check-input"
                    type="radio"
                    value="Units"
                  />
                  <label class="form-check-label" for="fieldSortUnits">
                    Units
                  </label>
                </div>
                <div
                  v-if="
                    (typeValue.type !== 'processor' ||
                      reportProcessor === true) &&
                      tableFilterPositions.expense === true
                  "
                  class="form-check mt-3 mb-3"
                >
                  <input
                    id="fieldSortExpense"
                    v-model="sortBy"
                    class="form-check-input"
                    type="radio"
                    value="Expense"
                  />
                  <label class="form-check-label" for="fieldSortExpense">
                    Expense
                  </label>
                </div>
                <div
                  v-if="
                    typeValue.type === 'processor' &&
                      tableFilterPositions.turnTime === true
                  "
                  class="form-check"
                >
                  <input
                    id="fieldTurnTime"
                    v-model="sortBy"
                    class="form-check-input"
                    type="radio"
                    value="Turn Time"
                  />
                  <label class="form-check-label" for="fieldTurnTime">
                    Turn Time
                  </label>
                </div>
                <div
                  v-if="
                    tableFilterPositions.volumeFunded === true
                  "
                  class="form-check mt-3 mb-3"
                >
                  <input
                    id="fieldSortExpense"
                    v-model="sortBy"
                    class="form-check-input"
                    type="radio"
                    value="Volume Funded"
                  />
                  <label class="form-check-label" for="fieldSortExpense">
                    Volume Funded
                  </label>
                </div>
                <span>Order</span>
                <div class="form-check">
                  <input
                    id="fieldHighLow"
                    v-model="order"
                    class="form-check-input"
                    type="radio"
                    value="Hi-Lo"
                  />
                  <label class="form-check-label" for="fieldHighLow">
                    High-Low
                  </label>
                </div>
                <div class="form-check mt-3">
                  <input
                    id="fieldLowHigh"
                    v-model="order"
                    class="form-check-input"
                    type="radio"
                    value="Lo-Hi"
                  />
                  <label class="form-check-label" for="fieldLowHigh">
                    Low-High
                  </label>
                </div>
              </div>
            </tippy>
          </div>
        </div>
        <div class="col-md-6 col-lg-2 mt-3 mt-lg-0">
          <button
            class="btn btn-outline-dark w-100"
            @click="runReport"
            :disabled="ui.loadingRunReport"
          >
            <span v-if="ui.loadingRunReport">loading...</span>
            <span v-else>Run Report</span>
          </button>
        </div>
      </div>
    </div>

    <!--  Loading spinner  -->
    <modal-spinner
      v-if="
        ui.loading === true ||
          ui.loadingAgent === true ||
          ui.loadingProcessor === true ||
          ui.loadingCompany === true
      "
      class="mt-4"
    />

    <!--  Filtration result  -->
    <div
      v-if="
        ui.loading === false &&
          ui.loadingAgent === false &&
          ui.loadingProcessor === false &&
          ui.loadingCompany === false
        "
      >
      <!-- Report for company -->
      <report-company
        v-if="typeValue.type === 'company'"
        :tableFilter="tableFilterPositions"
        :groupBy="groupedBy"
        :data="formData.companies"
      />

      <!-- Report for single agent -->
      <report-single-agent
        v-if="typeValue.type === 'agent' && reportAgent === true"
        :tableFilter="tableFilterPositions"
        :agentData="formData.selectedAgent"
      />

      <!-- Report for all agent -->
      <report-all-agent
        v-if="typeValue.type === 'agent' && reportAgent !== true"
        :tableFilter="tableFilterPositions"
        :groupBy="groupedBy"
        :data="formData.agents"
        :selectedAgent="selectedAgent"
        @showAgentReport="toggleAgentReport"
      />

      <!-- Report for processors -->
      <report-all-processors
        v-if="typeValue.type === 'processor' && reportProcessor !== true"
        :tableFilter="tableFilterPositions"
        :data="formData.processors"
        :selectedProcessor="selectedProcessor"
        @showProcessorReport="toggleProcessorReport"
      />

      <!-- Report for single processor -->
      <report-single-processor
        v-if="typeValue.type === 'processor' && reportProcessor === true"
        :tableFilter="tableFilterPositions"
        :processorData="formData.selectedProcessor"
      />
    </div>

    <!-- Add Report Name -->
    <vue-page-transition name="fade">
      <add-report-name
        :type="typeValue.type"
        :groupBy="groupedBy"
        :startDate="datePicked.startDate"
        :endDate="datePicked.endDate"
        :sortBy="sortBy"
        :orderBy="order"
        :show="tableFilterPositions"
        :processor="selectedProcessor"
        :agent="selectedAgent"
        :loanType="loanType"
        v-if="modals.saveReport === true"
        @close="modals.saveReport = false"
      />

      <report-rename-modal
        v-if="modals.renameReport"
        @close="closeRenameModal"
        @save="saveName"
        :report="selectedReport"
      />
    </vue-page-transition>
  </div>
</template>

<script>
import CreateHeaderReport from "./includes/CreateReportHeader";
import Multiselect from "vue-multiselect";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import ReportSingleProcessor from "./includes/ReportSingleProcessor";
import ReportSingleAgent from "./includes/ReportSingleAgent";
import ModalSpinner from "../../components/modal/ModalSpinner";
import ReportAllProcessors from "./includes/ReportAllProcessors";
import ReportAllAgent from "./includes/ReportAllAgent";
import ReportCompany from "./includes/ReportCompany";
import AddReportName from "./modals/AddReportName";
import ReportRenameModal from "./modals/ReportRenameModal";

export default {
  name: "CreateReport",
  components: {
    AddReportName,
    CreateHeaderReport,
    ReportSingleProcessor,
    ReportSingleAgent,
    ModalSpinner,
    ReportAllProcessors,
    ReportAllAgent,
    ReportCompany,
    Multiselect,
    DateRangePicker,
    ReportRenameModal
  },
  data() {
    return {
      title: null,
      typeValue: {
        title: "Company Report",
        type: "company"
      },
      selectedAgent: {
        id: 0,
        title: "All Agents"
      },
      selectedProcessor: {
        id: 0,
        title: "All Processors"
      },
      sortingValue: {},
      tableFilter: {},
      multiSelect: {
        types: [
          {
            title: "Agent Report",
            type: "agent"
          },
          {
            title: "Processor Report",
            type: "processor"
          },
          {
            title: "Company Report",
            type: "company"
          }
        ],
        agents: [{ id: 0, title: "All Agents" }],
        processors: [{ id: 0, title: "All Processors" }],
        tableFilters: [],
        sortables: []
      },
      tableFilterPositions: {
        revenue: true,
        units: true,
        expense: true,
        turnTime: false,
        withdrawal: false,
        volumeFunded: true,
      },
      loanType: [],
      dateOptions: [],
      datePicked: {
        title: "",
        range: "",
        startDate: "",
        endDate: ""
      },
      ui: {
        loading: false,
        loadingAgent: false,
        loadingProcessor: false,
        loadingCompany: false,
        loadingExport: false,
        loadingRunReport: false
      },
      dateRange: {
        startDate: new Date()
      },
      dateRangeEnd: {
        startDate: new Date()
      },
      customRange: {
        startDate: moment(String(new Date())).format("MMM DD YYYY"),
        endDate: moment(String(new Date())).format("MMM DD YYYY")
      },
      pickedDateRange: "",
      sortBy: "Units",
      order: "Hi-Lo",
      groupedBy: "Month",
      reportAgent: false,
      reportProcessor: false,
      modals: {
        saveReport: false,
        renameReport: false
      },
      reportData: {
        name: "Test Report"
      },
      processorData: null,
      formData: {
        companies: {
          title: "Clear Mortgage Capital",
          subTitle: "Inc.",
          totalRevenue: 0,
          totalExpense: 0,
          totalUnits: 0,
          data: []
        },
        agents: [],
        processors: [],
        selectedAgent: {},
        selectedProcessor: {}
      },
      isAdminstrator: false,
      isEdit: false,
      selectedReport: null
    };
  },
  methods: {
    setDateList() {
      // Func for getting date for custom datepicker dropdown
      const date = new Date();
      const previousDate = new Date();
      const formatOptions = {
        year: "numeric",
        month: "short",
        day: "numeric"
      };

      // Get previous month
      previousDate.setDate(1);
      previousDate.setMonth(previousDate.getMonth() - 1);

      this.dateOptions = [
        {
          id: 1,
          title: "This month",
          date:
            new Date(date.getFullYear(), date.getMonth(), 1).toLocaleString(
              "en-us",
              formatOptions
            ) +
            " - " +
            new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleString(
              "en-us",
              formatOptions
            ),
          startDate: new Date(
            date.getFullYear(),
            date.getMonth(),
            1
          ).toLocaleString("en-us", formatOptions),
          endDate: new Date(
            date.getFullYear(),
            date.getMonth() + 1,
            0
          ).toLocaleString("en-us", formatOptions),
          isChecked: false
        },
        {
          id: 2,
          title: "Last Month",
          date:
            new Date(
              previousDate.getFullYear(),
              previousDate.getMonth(),
              previousDate.getDay()
            ).toLocaleString("en-us", formatOptions)
            +
            " - " +
            new Date(
              previousDate.getFullYear(),
              date.getMonth(),
              0
            ).toLocaleString("en-us", formatOptions),
          startDate: previousDate.toDateString(),
          endDate: new Date(
            previousDate.getFullYear(),
            date.getMonth(),
            0
          ).toLocaleString("en-us", formatOptions),
          isChecked: false
        },
        {
          id: 3,
          title: "Last 30 days",
          date:
            new Date(
              new Date().setDate(new Date().getDate() - 30)
            ).toLocaleString("en-us", formatOptions) +
            " - " +
            new Date().toLocaleString("en-us", formatOptions),
          startDate: new Date(
            new Date().setDate(new Date().getDate() - 30)
          ).toLocaleString("en-us", formatOptions),
          endDate: new Date().toLocaleString("en-us", formatOptions),
          isChecked: false
        },
        {
          id: 4,
          title: "This Year",
          date:
            new Date(new Date().getFullYear(), 0, 1).toLocaleString(
              "en-us",
              formatOptions
            ) +
            " - " +
            new Date().toLocaleString("en-us", formatOptions),
          startDate: new Date(new Date().getFullYear(), 0, 1).toLocaleString(
            "en-us",
            formatOptions
          ),
          endDate: new Date().toLocaleString("en-us", formatOptions),
          isChecked: true
        },
        {
          id: 5,
          title: "Last Year",
          date:
            new Date(new Date().getFullYear() - 1, 0, 1).toLocaleString(
              "en-us",
              formatOptions
            ) +
            " - " +
            new Date(new Date().getFullYear(), 0, 0).toLocaleString(
              "en-us",
              formatOptions
            ),
          startDate: new Date(
            new Date().getFullYear() - 1,
            0,
            1
          ).toLocaleString("en-us", formatOptions),
          endDate: new Date(new Date().getFullYear(), 0, 0).toLocaleString(
            "en-us",
            formatOptions
          ),
          isChecked: false
        },
        {
          id: 6,
          title: "Alltime",
          date:
            new Date(2019, 0, 1).toLocaleString("en-us", formatOptions) +
            " - " +
            new Date().toLocaleString("en-us", formatOptions),
          startDate: new Date(2019, 0, 1).toLocaleString(
            "en-us",
            formatOptions
          ),
          endDate: new Date().toLocaleString("en-us", formatOptions),
          isChecked: false
        },
        {
          id: 7,
          title: "Custom Range",
          date: this.customRange.startDate + " - " + this.customRange.endDate,
          startDate: this.customRange.startDate,
          endDate: this.customRange.endDate,
          isChecked: false
        }
      ];
      // set checked date
      this.dateOptions = this.dateOptions.map(el => {
        if (el.isChecked === true) {
          this.datePicked.range = el.date;
          this.datePicked.title = el.title;
          this.datePicked.startDate = el.startDate;
          this.datePicked.endDate = el.endDate;
        }
        return el;
      });
    },
    setDate(id) {
      // Func for set date in custom date picker
      this.setDateList();
      this.dateOptions = this.dateOptions.map(el => {
        if (el.id === id) {
          el.isChecked = true;
          this.datePicked.range = el.date;
          this.datePicked.title = el.title;
          this.datePicked.startDate = el.startDate;
          this.datePicked.endDate = el.endDate;
        } else {
          el.isChecked = false;
        }
        return el;
      });
    },
    datepickerSelect(val) {
      // Func when pick start date and set up in variable
      this.customRange.startDate = moment(String(val.startDate)).format(
        "MMM DD YYYY"
      );

      // remove checked for other date item
      this.setDateList();
      this.dateOptions = this.dateOptions.map(el => {
        if (el.title === "Custom Range") {
          el.isChecked = true;
          this.datePicked.range = el.date;
          this.datePicked.title = el.title;
          this.datePicked.startDate = el.startDate;
          this.datePicked.endDate = el.endDate;
        } else {
          el.isChecked = false;
        }
        return el;
      });
    },
    datepickerSelectEnd(val) {
      // Func when pick end date and set up in variable

      this.customRange.endDate = moment(String(val.startDate)).format(
        "MMM DD YYYY"
      );

      // remove checked for other date item
      this.setDateList();
      this.dateOptions = this.dateOptions.map(el => {
        if (el.title === "Custom Range") {
          el.isChecked = true;
          this.datePicked.range = el.date;
          this.datePicked.title = el.title;
          this.datePicked.startDate = el.startDate;
          this.datePicked.endDate = el.endDate;
        } else {
          el.isChecked = false;
        }
        return el;
      });
    },
    toggleAgentReport(val) {
      this.ui.loadingAgent = true;

      // Change multiselect selected data
      this.typeValue.type = "agent";
      this.formData.selectedAgent = val;
      if (val.id > 0) {
        this.selectedAgent = {
          id: val.id,
          title: val.name
        };
        this.reportAgent = true;
      } else {
        this.selectedProcessor = {
          id: 0,
          title: "All Processors"
        };
        this.selectedAgent = {
          id: 0,
          title: "All Agents"
        };
        this.reportProcessor = false;
        this.reportAgent = false;
      }

      setTimeout(() => {
        this.ui.loadingAgent = false;
      }, 500);
    },
    toggleProcessorReport(val) {
      this.ui.loadingProcessor = true;

      // Change multiselect selected data
      this.typeValue.type = "processor";
      this.formData.selectedProcessor = val;
      if (val.id > 0) {
        this.selectedProcessor = {
          id: val.id,
          title: val.name
        };
        this.reportProcessor = true;
      } else {
        this.selectedProcessor = {
          id: 0,
          title: "All Processors"
        };
        this.selectedAgent = {
          id: 0,
          title: "All Agents"
        };
        this.reportProcessor = false;
        this.reportAgent = false;
      }

      setTimeout(() => {
        this.ui.loadingProcessor = false;
      }, 500);
    },
    selectAgent(val) {
      // Func for select report agent
      this.ui.loadingAgent = true;
      // this.formData.selectedAgent = this.formData.agents.filter(agent => agent.id === val.id)
      this.formData.agents.forEach(value => {
        if (value.id === val.id) {
          this.formData.selectedAgent = value;
        }
      });

      if (Object.keys(this.formData.selectedAgent).length > 0) {
        if (this.formData.selectedAgent.id !== val.id) {
          this.formData.selectedAgent = {};
          this.reportAgent = false;
          this.ui.loadingAgent = false;
        } else {
          setTimeout(() => {
            this.typeValue.type = "agent";
            this.reportAgent = val.title !== "All Agents";
            this.ui.loadingAgent = false;
          }, 500);
        }
      } else {
        this.ui.loadingAgent = false;
      }
    },
    selectProcessor(val) {
      // Func for select report processor
      this.ui.loadingProcessor = true;
      this.formData.processors.forEach(value => {
        if (value.id === val.id) {
          this.formData.selectedProcessor = value;
        }
      });

      if (Object.keys(this.formData.selectedProcessor).length > 0) {
        if (this.formData.selectedProcessor.id !== val.id) {
          this.formData.selectedProcessor = {};
          this.reportProcessor = false;
          this.ui.loadingProcessor = false;
        } else {
          setTimeout(() => {
            this.typeValue.type = "processor";
            this.reportProcessor = val.title !== "All Processors";
            this.ui.loadingProcessor = false;
          }, 500);
        }
      } else {
        this.ui.loadingProcessor = false;
      }
    },
    append() {
      return document.getElementById("pickDate");
    },
    tableFilterAppend() {
      return document.getElementById("table-filter");
    },
    sortableAppend() {
      return document.getElementById("sortable");
    },
    selectType(val) {
      // Func for select report type
      this.selectedProcessor = {
        id: 0,
        title: "All Processors"
      };
      this.selectedAgent = {
        id: 0,
        title: "All Agents"
      };

      this.sortBy = "Units";
      this.reportProcessor = false;
      this.reportAgent = false;

      if (val.type == "agent") {
        this.getAgentData();
      } else if (val.type == "processor") {
        this.getProcessorData();
      } else {
        this.getCompanyData();
      }
    },
    runReport() {
      this.ui.loadingRunReport = true;
      if (this.typeValue.type === "company") {
        this.getCompanyData();
      } else if (this.typeValue.type === "agent") {
        this.getAgentData();
      } else {
        this.getProcessorData();
      }
    },
    getData() {
      this.ui.loading = true;
      this.$http
        .get(`/api/v1/reports/transaction/base`)
        .then(res => {
          res.data.processors.unshift({
            id: 0,
            title: "All Processors"
          });

          res.data.agents.unshift({
            id: 0,
            title: "All Agents"
          });

          this.multiSelect.agents = res.data.agents
          this.multiSelect.processors = res.data.processors

          if (!this.isEdit) {
            this.loanType = res.data.loanType;
            if (this.typeValue.type === "company") {
              this.getCompanyData();
            } else if (this.typeValue.type === "agent") {
              this.getAgentData();
            } else {
              this.getProcessorData();
            }
          }
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.loading = false;
        });
    },
    getCompanyData() {
      this.ui.loadingCompany = true;
      this.$http
        .get(`/api/v1/reports/transaction/company`, {
          params: {
            groupBy: this.groupedBy.toLowerCase(),
            startDate: moment(String(this.datePicked.startDate)).format(
              "YYYY-MM-DD"
            ),
            endDate: moment(String(this.datePicked.endDate)).format(
              "YYYY-MM-DD"
            ),
            sortBy: this.sortBy.toLowerCase(),
            orderBy: this.order.toLowerCase(),
            loanType: Object.assign({}, this.loanType),
          }
        })
        .then(res => {
          this.formData.companies = res.data;
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.loadingCompany = false;
          this.ui.loadingRunReport = false;
        });
    },
    getAgentData() {
      this.ui.loadingAgent = true;
      let url = this.isAdminstrator ? `/api/v1/reports/transaction/agent` : `/api/v1/reports/transaction/agent/${this.$store.getters.getUserId}`;
      this.$http
        .get(url, {
          params: {
            startDate: moment(String(this.datePicked.startDate)).format(
              "YYYY-MM-DD"
            ),
            endDate: moment(String(this.datePicked.endDate)).format(
              "YYYY-MM-DD"
            ),
            sortBy: this.sortBy.toLowerCase(),
            orderBy: this.order.toLowerCase(),
            loanType: Object.assign({}, this.loanType),
          }
        })
        .then(res => {
          this.formData.agents = res.data;
          if (this.selectedAgent.id > 0) {
            this.formData.agents.forEach(value => {
              if (value.id === this.selectedAgent.id) {
                this.formData.selectedAgent = value;
              }
            });
          }
          this.selectAgent(this.selectedAgent);
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.loadingAgent = false;
          this.ui.loadingRunReport = false;
        });
    },
    getProcessorData() {
      this.ui.loadingProcessor = true;
      let url = this.isAdminstrator ? `/api/v1/reports/transaction/processor` : `/api/v1/reports/transaction/processor/${this.$store.getters.getUserId}`
      this.$http
        .get(url, {
          params: {
            startDate: moment(String(this.datePicked.startDate)).format(
              "YYYY-MM-DD"
            ),
            endDate: moment(String(this.datePicked.endDate)).format(
              "YYYY-MM-DD"
            ),
            sortBy: this.sortBy.toLowerCase(),
            orderBy: this.order.toLowerCase(),
            loanType: Object.assign({}, this.loanType),
          }
        })
        .then(res => {
          this.formData.processors = res.data;
          if (this.selectedProcessor.id > 0) {
            this.formData.processors.forEach(value => {
              if (value.id === this.selectedProcessor.id) {
                this.formData.selectedProcessor = value;
              }
            });
          }
          this.selectProcessor(this.selectedProcessor);
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.loadingProcessor = false;
          this.ui.loadingRunReport = false;
        });
    },
    exportReport() {
      this.ui.loadingExport = true;
      let user_id = this.selectedProcessor.id;
      if (user_id === 0) {
        user_id = this.selectedAgent.id;
      }

      let queryParams = {
        startDate: moment(String(this.datePicked.startDate)).format(
          "YYYY-MM-DD"
        ),
        endDate: moment(String(this.datePicked.endDate)).format("YYYY-MM-DD"),
        type: this.typeValue.type.toLowerCase(),
        groupBy: this.groupedBy.toLowerCase(),
        sortBy: this.sortBy.toLowerCase(),
        orderBy: this.order.toLowerCase(),
        show: this.tableFilterPositions,
        userId: user_id,
        loanType: Object.assign({}, this.loanType),
      };

      this.$http
        .get(`/api/v1/reports/transaction/export`, {
          params: queryParams
        })
        .then(res => {
          let fileURL = window.URL.createObjectURL(new Blob([res.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            this.typeValue.type.toLowerCase() +
              "-report-" +
              moment().format("DD-MMM-YYYY") +
              ".csv"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.loadingExport = false;
        });
    },
    prepareView() {
      if (!this.isAdminstrator) {
        if (this.isGranted("ROLE_PROCESSOR")) {
          this.typeValue = this.multiSelect.types[1];
        } else {
          this.typeValue = this.multiSelect.types[0];
        }
      }
    },
    getReportById() {
      this.$http
        .get(`/api/v1/reports/${this.$route.params.id}/get`)
        .then(res => {
          this.selectedReport = res.data;
          this.loanType = this.selectedReport.loanType;
          this.title = this.selectedReport.name;
          this.typeValue = this.multiSelect.types.find((type) => type.type === this.selectedReport.type);
          this.groupedBy = this.formatStringValue(this.selectedReport.groupBy);
          this.sortBy = this.formatStringValue(this.selectedReport.sortBy);
          this.order = this.selectedReport.orderBy === 'hi-lo' ? 'Hi-Lo': 'Lo-Hi';
          this.dateRange.startDate = moment(String(this.selectedReport.startDate)).format("MMM D, YYYY");
          this.dateRangeEnd.startDate = moment(String(this.selectedReport.endDate)).format("MMM D, YYYY");
          let isDateUpdated = false;
          this.dateOptions = this.dateOptions.map(el => {
            if (el.date === `${this.dateRange.startDate} - ${this.dateRangeEnd.startDate}`) {
              isDateUpdated = true;
              el.isChecked = true;
              this.datePicked.range = el.date;
              this.datePicked.title = el.title;
              this.datePicked.startDate = el.startDate;
              this.datePicked.endDate = el.endDate;
            }else if (el.title === "Custom Range" && !isDateUpdated) {
              isDateUpdated = true;
              el.isChecked = true;
              el.date = `${this.dateRange.startDate} - ${this.dateRangeEnd.startDate}`;
              el.startDate = this.dateRange.startDate;
              el.endDate = this.dateRangeEnd.startDate;
              this.datePicked.range = el.date;
              this.datePicked.title = el.title;
              this.datePicked.startDate = el.startDate;
              this.datePicked.endDate = el.endDate;
            } else {
              el.isChecked = false;
            }
            return el;
          });

          for (let position in this.tableFilterPositions) {
            let positionExist = this.selectedReport.show.some(function(field) {
                return field === position;
            });
            this.tableFilterPositions[position] = (positionExist) ? true : false;
          }

          if (this.selectedReport.type === "company") {
            this.getCompanyData();
          } else if (this.selectedReport.type === "agent") {
            this.getAgentData();
            this.selectedAgent = this.selectedReport.user;
          } else {
            this.getProcessorData();
            this.selectedProcessor = this.selectedReport.user;
          }
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        });
    },
    save() {
      if (this.isEdit) {
        let user_id = this.selectedProcessor.id;
        if (user_id === 0) {
          user_id = this.selectedAgent.id;
        }

        let formData = new FormData();
        if (this.tableFilterPositions.revenue === true) {
          formData.append("show[]", "revenue");
        }
        if (this.tableFilterPositions.units === true) {
          formData.append("show[]", "units");
        }
        if (this.tableFilterPositions.expense === true) {
          formData.append("show[]", "expense");
        }
        if (this.tableFilterPositions.withdrawal === true) {
          formData.append("show[]", "withdrawal");
        }
        if (this.tableFilterPositions.turnTime === true) {
          formData.append("show[]", "turnTime");
        }
        if (this.tableFilterPositions.volumeFunded === true) {
          formData.append("show[]", "volumeFunded");
        }

        formData.append("name", this.title);
        formData.append("type", this.typeValue.type.toLowerCase());
        formData.append("groupBy", this.groupedBy.toLowerCase());
        formData.append("startDate", moment(String(this.datePicked.startDate)).format('YYYY-MM-DD'));
        formData.append("endDate", moment(String(this.datePicked.endDate)).format('YYYY-MM-DD'));
        formData.append("sortBy", this.sortBy.toLowerCase());
        formData.append("orderBy", this.order.toLowerCase());
        formData.append("userId", user_id);
        formData.append("loanType", JSON.stringify(this.loanType));      
        this.ui.loading =true
        this.$http
          .post(`/api/v1/reports/${this.$route.params.id}/edit`, formData)
          .then(() => {
            this.alertSuccess("Report updated succesfully!");
            this.ui.loading = false
            setTimeout(() => {
              this.$emit('close')
            }, 1000)
          })
          .catch(err => {
            this.alertError(err.response.statusText);
          });
      }
      else {
        this.modals.saveReport = true;
      }
    },
    closeRenameModal() {
      this.modals.renameReport = false
    },
    openRenameModal() {
      this.modals.renameReport = true
    },
    saveName(val) {
      this.title = val;
      this.selectedReport.name = this.title;
      this.closeRenameModal();
    },
    formatStringValue(str) {
      let convertString = str => str.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase());
      return convertString(str.replace("_", " "));
    }
  },
  watch: {
    typeValue() {
      this.reportAgent = false;
      this.reportProcessor = false;
    }
  },
  filters: {
    date(date) {
      return moment(String(date)).format("MMM DD YYYY");
    }
  },
  created() {
    this.isAdminstrator =
      this.isGranted("ROLE_CEO") ||
      this.isGranted("ROLE_CEO_ASSISTANT") ||
      this.isGranted("ROLE_BRANCH_MANAGER");
    this.isEdit = (this.$route.params.id)? true:false;
    this.setDateList();
    this.prepareView();
    this.getData();

    if (this.isEdit) {
      this.getReportById();
    }
  }
};
</script>

<style lang="scss" scoped>
.report-details {
  padding: 18px 30px;
  background-color: #f6f6f6;

  &::v-deep {
    .multiselect {
      min-height: 36px;
      max-height: 36px;
      min-width: 100%;
      width: fit-content;
      @media screen and (max-width: 568px) {
        max-width: 50%;
        min-width: 100%;
      }

      &__tags {
        min-height: 36px;
        max-height: 36px;
        padding: 6px 40px 0 8px;
        transition: all 0.3s;
      }

      &__select {
        min-height: 36px;
        max-height: 36px;

        &:before {
          top: 15%;
          position: relative;
          right: 0;
          color: #999;
          border: solid rgba(0, 0, 0, 0.46);
          margin-top: 4px;
          border-width: 0 1px 1px 0;
          display: inline-block;
          padding: 3px;
          content: "";
          transform: rotate(44deg);
        }
      }

      &__single {
        color: rgba(0, 0, 0, 0.46);
      }

      &__option {
        min-height: 36px;
        transition: all 0.3s;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }

        &--selected {
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 18px;
        }

        &--highlight {
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 18px;
          @media screen and (max-width: 568px) {
            font-size: 9px;
          }
        }
      }
    }
  }
}

.data-category::v-deep {
  .multiselect {
    .multiselect__content-wrapper {
      border-radius: 8px;
    }

    &.multiselect--active {
      .multiselect__tags {
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
        border-radius: 8px;
      }
    }

    &__tags {
      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__single {
      color: rgba(0, 0, 0, 0.46);
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }

    &__option {
      padding: 10px 12px;
      color: rgba(0, 0, 0, 0.7);

      div {
        display: inline;
        margin-left: 15px;
      }

      &--selected {
        background-color: rgba(1, 122, 255, 0.05);
        color: #017aff;

        div {
          display: inline;
          margin-left: 5px;
        }

        &:before {
          content: "\2714";
          color: #0078ff;
          background: none;
        }
      }

      &--highlight {
        background-color: rgba(1, 122, 255, 0.05);
        color: #017aff;
      }
    }

    .report-type {
      opacity: 0.46;
      color: #000000;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 10px;
    }

    .option__title {
      display: inline;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
      margin-left: 10px;
    }
  }
}

.table-filter::v-deep {
  .multiselect {
    @media screen and (max-width: 568px) {
      max-width: 50%;
      min-width: 100%;
    }

    &.multiselect--active {
      .multiselect__tags {
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__tags {
      padding: 6px 40px 0 8px;
      transition: all 0.3s;

      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__select {
      min-height: 36px;
      max-height: 36px;
    }

    &__single {
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }

    &__option {
      padding: 24px;
      min-height: 36px;
      transition: all 0.3s;
      color: rgba(0, 0, 0, 0.7);

      div {
        display: inline;
        margin-left: 0;
      }

      &--selected {
        background-color: rgba(1, 122, 255, 0.05);
        color: #0a0a0a;
      }

      &--highlight {
        background-color: unset;
        color: #0a0a0a;
      }
    }

    .report-type {
      color: rgba(0, 0, 0, 0.46);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 10px;

      span {
        display: inline;
        margin-right: 5px;
        color: #000000;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
        opacity: 1;
      }
    }

    .option__title {
      display: inline;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
      margin-left: 10px;
    }

    .option-label {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
    }

    .form-group {
      display: flex;
      align-items: center;

      label {
        margin-bottom: 0;
        color: #000000;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 14px;
        font-weight: normal;
        margin-left: 10px;
      }

      .form-check-input {
        width: 18px;
        height: 18px;
        padding: 0;
        max-width: 18px;
        margin: 0;

        &:checked {
          border: 2px solid #017aff;
          background-color: #017aff;
        }
      }
    }
  }
}

.setDate-block {
  ul {
    list-style: none;
    padding: 12px 0;

    li {
      color: rgba(0, 0, 0, 0.7);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: start;
      padding: 9px 30px;
      cursor: pointer;
      transition: all 0.3s;

      &.is-checked {
        position: relative;
        background-color: rgba(1, 122, 255, 0.05);
        color: #017aff;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;

        &:before {
          position: absolute;
          top: 9px;
          left: 10px;
          content: "\2714";
          color: #0078ff;
          background: none;
          margin-right: 6px;
          font-weight: normal;
        }

        span {
          font-weight: normal;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        color: rgba(0, 0, 0, 1);
      }

      span {
        color: rgba(0, 0, 0, 0.46);
        font-size: 11px;
        letter-spacing: 0;
        line-height: 17px;
      }
    }
  }
}

.custom-date {
  padding: 17.5px 30px;
  border-top: 1px solid #e7e8e8;

  &::v-deep {
    .daterange-control {
      .reportrange-text {
        color: #000000;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 15px;
        height: 36px;
        min-height: 36px;
      }

      img {
        right: 5px;
      }
    }
  }
}

.sortable-block {
  min-width: 202px;
  text-align: start;
  padding: 24px;

  span {
    display: block;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 9px;
  }

  .form-check {
    padding: 0;

    label {
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
    }

    &-input {
      max-width: 18px;
      width: 100%;
      height: 18px;
      margin: 0;
      &[type="radio"] {
        &:active,
        &:focus,
        &:hover {
          box-shadow: none;
        }
        appearance: auto;
      }
      &:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
      }
    }
  }
}

#pickDate,
#sortable,
#table-filter {
  position: relative;

  &::v-deep {
    .tippy-popper {
      width: 100%;

      .tippy-tooltip {
        width: 100%;
        max-width: 100% !important;
      }
    }
  }
}

#table-filter {
  .report-type {
    opacity: 1;
    color: rgba(0, 0, 0, 0.46);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;

    span {
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
      font-weight: normal;
      opacity: 1;

      &.normal {
        color: rgba(0, 0, 0, 0.46);
      }
    }
  }
}
</style>
