<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <router-link :to="{ name: 'reports' }">
          <button class="btn btn-control">
            <img src="@/assets/icons/bold-icon previous.svg" alt="Return back">
          </button>
        </router-link>
        <h3 class="page-header-title ms-2">
          <span v-if="!isEdit">Create</span> Report
        </h3>
        <div v-if="isEdit" class="subtitle">
          {{title}}
          <img src="@/assets/icons/edit.svg" alt="Edit Title" class="btn btn-link m-2" role="button" @click="$emit('editTitleModel')">
        </div>
      </div>
      <div class="d-flex align-items-center">
        <router-link :to="{ name: 'reports' }" class="text-decoration-none">
          <base-button title="Discard" action="temp" />
        </router-link>
        <base-button :title="saveButtonTitle" action="primary" @click-btn="$emit('save')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../../components/BaseButton.vue'
export default {
  name: 'CreateHeaderReport',
  components: { BaseButton },
  props: {
    isEdit: Boolean,
    title: String
  },
  computed: {
    saveButtonTitle() {
      return 'Save ' + ( this.isEdit ? 'Customization' : 'and Create' )
    }
  }
}
</script>

<style lang="scss" scoped>
.page-header {
  a {
    text-decoration: none;
  }
}
.subtitle {
  margin-left:20px;
  color: #838384;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
}
</style>
